import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ToastrService } from 'ngx-toastr';
//import { ComentarioService } from 'src/app/servicios/comentario.service';
import { ComentarioService } from 'src/app/comentario.service';
//import {resultList,SpeechRecognitionService} from '@kamiazya/ngx-speech-recognition';

@Component({
  selector: 'wsa-observacion',
  templateUrl: './observacion.component.html',
  styleUrls: ['./observacion.component.css']
})
export class ObservacionComponent {

  listTarjetas: any;

  form: FormGroup;

  comentario: any;
  private synth = window.speechSynthesis;
  message = '';
  listen = ""

  constructor(private fb: FormBuilder,  private _comentario: ComentarioService) {

    this.form = this.fb.group({
      nombre: ['', Validators.required],
      comentario: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(1)]],

    }

    )

  }
  ngOnInit(): void {
    this.obtenerComentarios();
  }

  agregarTarjeta() {

    //console.log(this.form);


    const TARJETA: any = {
      nombre: this.form.get('nombre')?.value,
      comentario: this.form.get('comentario')?.value,
      //fechaExpiracion:this.form.get('fechaExpiracion')?.value,
      //cvv:this.form.get('cvv')?.value,

    }
    console.log(TARJETA);
    this._comentario.guardar(TARJETA).subscribe(data => {
    //  this.toastr.success('Registrado', 'tarj resgistrada');
      //this.router.navigate(['/']);
    }, error => {
      console.log(error);
      this.form.reset();
    })

    this.listTarjetas.push(TARJETA)
  //  this.toastr.success('Comentario registrado con exito!', 'Comentario registrado!');
    this.form.reset();

  }

  eliminarTarjeta(id: any) {
    this._comentario.eliminar(id).subscribe(data => {
   //   this.toastr.error('Tarjeta eliminada con exito', 'Tarjeta eliminada');
      this.obtenerComentarios();
    }, error => {
      console.log(error);
    }
    );

  }

  obtenerComentarios() {
    this._comentario.obtener()
      .subscribe(data => {
        console.log(data);
        this.listTarjetas = data;
        console.log(this.comentario);
      })
  }

  startSpeechRecognition(fieldName: string) {
    const recognition = new window.webkitSpeechRecognition(); // Crear instancia de reconocimiento de voz
    recognition.lang = 'es'; // Establecer idioma en español
    recognition.start(); // Iniciar reconocimiento de voz

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript; // Obtener la transcripción del primer resultado
      console.log(transcript); // Mostrar la transcripción en la consola (puedes quitar esta línea después de verificar que funciona correctamente)
      // Actualizar el valor del campo de entrada de texto con la transcripción
      this.form.get(fieldName)?.setValue(transcript);
    };
  }
  
}

