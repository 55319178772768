<div class="container mt-5">
    <div class="row mt-3">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body text-center">
                    <th>Agregar Estudiante</th>
                    <form [formGroup]="form" (ngSubmit)="agregarTarjeta()">
                        <div class="input-group input-group-lg mb-3">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-user"
                                [class.green-icon]="this.form.get('nombre')?.valid"
                                [class.red-icon]="this.form.get('nombre')?.invalid && this.form.get('nombre')?.touched"></i>
                            </span>
                            <input formControlName="nombre" type="text" class="form-control" placeholder="Nombre" aria-label="Username" aria-describedby="basic-addon1">
                            <button type="button" class="btn btn-primary" (click)="startSpeechRecognition('nombre')">Dictar</button>
                        
                        </div>
                        <div class="input-group input-group-lg mb-3">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-credit-card"></i></span>
                            <input formControlName="asignatura" maxlength="16" type="text" class="form-control" placeholder="Asignatura" aria-label="Username" aria-describedby="basic-addon1">
                            <button type="button" class="btn btn-primary" (click)="startSpeechRecognition('asignatura')">Dictar</button>
                        </div>
                        <div class="d-grid gap-2">
                            <button  type="submit" class="btn btn-success btn-lg">
                                <i class="fas fa-database"></i>&nbsp;Aceptar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body text-center">
                    <!--<h5>Lista</h5>-->
                    <table class="table"> 
                        <tr>
                            <th>Nombre</th>
                            <th>Asignatura</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let comentario of listTarjetas; let i = index">
                                <td>{{comentario.nombre}}</td>
                                <td>{{comentario.asignatura}}</td>
                                <td><i class="far fa-edit text-info"></i></td>
                                <td><i (click)="eliminarTarjeta(comentario._id)" class="far fa-trash-alt text-danger"></i></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</div>
