import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { WebSpeechModule } from './web-speech/web-speech.module';
import { ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { TarjetaCreditoComponent } from './tarjeta-credito/tarjeta-credito.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
//import { OAuthModule } from 'angular-oauth2-oidc';
import { FormsModule } from '@angular/forms';
import { ObservacionComponent } from './observacion/observacion.component';

@NgModule({
  declarations: [
    AppComponent,
    TarjetaCreditoComponent,
    LoginComponent,
    MainComponent,
    ObservacionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    WebSpeechModule,

  
    ReactiveFormsModule,
    
    //ToastrModule.forRoot(),//agregar
    HttpClientModule,
    FormsModule
   // OAuthModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
