<h2 mat-dialog-title>Available Actions</h2>
<mat-dialog-content class="mat-typography">
  <h3>Continuous Dictation</h3>
  <p>
    Just press the microphone and start talking. You should be able to see the text result into the main textarea.
    Try to write a letter with your voice!
  </p>

  <h3>Change the Application Theme</h3>
  <p>
    Once the microphone is active, try the following voice commands:
  </p>

  <table>
    <caption>English</caption>
    <tr>
      <th>Voice Command</th>
      <th>Action</th>
    </tr>
    <tr>
      <td>"Perform Change Theme"</td>
      <td>Enables the Change Theme Strategy</td>
    </tr>
    <tr>
      <td>"Finish Change Theme"</td>
      <td>Finishes the Change Theme Strategy</td>
    </tr>
  </table>
  <p>Available theme names: "deep purple", "indigo", "pink" and "purple"</p>

  <table>
    <caption>Español</caption>
    <tr>
      <th>Comando de Voz</th>
      <th>Acción</th>
    </tr>
    <tr>
      <td>"Iniciar Cambio de Tema"</td>
      <td>Habilita la Estrategia para el cambio de Tema</td>
    </tr>
    <tr>
      <td>"Finalizar Cambio de Tema"</td>
      <td>Finaliza la Estrategia para el cambio de Tema</td>
    </tr>
  </table>
  <p>Nombres de tema disponibles: "púrpura", "azul", "rosa" and "verde"</p>

  <h3>Change the Application Title</h3>
  <p>
    Once the microphone is active, try the following voice commands:
  </p>
  <table>
    <caption>English</caption>
    <tr>
      <th>Voice Command</th>
      <th>Action</th>
    </tr>
    <tr>
      <td>"Perform Change Title"</td>
      <td>Enables the Change Title Strategy</td>
    </tr>
    <tr>
      <td>"Finish Change Title"</td>
      <td>Finishes the Change Title Strategy</td>
    </tr>
  </table>

  <table>
    <caption>Español</caption>
    <tr>
      <th>Comando de Voz</th>
      <th>Acción</th>
    </tr>
    <tr>
      <td>"Iniciar Cambio de Título"</td>
      <td>Habilita la Estrategia para el cambio de Título</td>
    </tr>
    <tr>
      <td>"Finalizar Cambio de Título"</td>
      <td>Finaliza la Estrategia para el cambio de Título</td>
    </tr>
  </table>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
