  <section>
    <mat-card *ngIf="errorMessage$| async as errorMessage" class="notification">{{errorMessage}}</mat-card>
  </section>
  <section>
    <mat-form-field>
      <mat-label>Seleccionar idioma</mat-label>
      <mat-select [(value)]="currentLanguage">
        <mat-option *ngFor="let language of languages" [value]="language" (click)="selectLanguage(language)">
          {{language}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <section>
    <mat-form-field>
      <mat-label>Seleccionar estudiante</mat-label>
      <mat-select [(value)]="selectedStudent">
        <mat-option *ngFor="let comentario of listTarjetas" [value]="comentario.nombre">{{comentario.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <section>
    <button mat-fab *ngIf="listening$ | async; else mic" (click)="stop()">
      <mat-icon class="soundwave"  disabled="false">mic</mat-icon>
    </button>
    <ng-template #mic>
      <button mat-fab (click)="start()">
        <mat-icon>mic</mat-icon>
      </button>
    </ng-template>
  </section>
<section *ngIf="transcript$ | async">
  <mat-card class="notification mat-elevation-z4">{{transcript$ | async}}</mat-card>
</section>
<section>
  <mat-form-field class="speech-result-width">
    <textarea matInput [(ngModel)]="totalTranscript" placeholder="Observador del alumno" rows="15"></textarea>
    </mat-form-field>
  <button mat-button (click)="guardarPost()" color="primary">Guardar</button>
</section>
