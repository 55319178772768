import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(private router: Router) {}
  username: string | undefined;
  password: string | undefined;
  

  login() {
    // Aquí deberías validar el usuario y la contraseña con algún servicio o API
    // En este ejemplo, simplemente estamos quemando las credenciales
    if (this.username === 'docente1' && this.password === 'docente.1') {
      this.router.navigate(['/reporte']);
      //alert('Inicio de sesión exitoso');
    } else {
      alert('Usuario o contraseña incorrectos');
    }
  }
}
