<div class="container mt-5">
    <div class="row mt-3">
        
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body text-center">
                    <!--<h5>Lista</h5>-->
                    <table class="table"> 
                        <tr>
                            <th>Estudiante</th>
                            <th>Observacion</th>
                            <th>Fecha</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let comentario of listTarjetas; let i = index">
                                <td>{{comentario.nombre}}</td>
                                <td>{{comentario.comentario}}</td>
                                <td>{{comentario.fecha}}</td>
                                <td><i class="far fa-edit text-info"></i></td>
                                <td><i (click)="eliminarTarjeta(comentario._id)" class="far fa-trash-alt text-danger"></i></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</div>
