<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a mat-list-item routerLink="reporte">
        <mat-icon mat-list-icon>person</mat-icon>
        <span mat-line>Agregar estudiante</span>
      </a>
      <a mat-list-item routerLink="registrar">
        <mat-icon mat-list-icon>credit_card</mat-icon>
        <span mat-line>Agregar Observacion</span>
      </a>
      <a mat-list-item routerLink="observador">
        <mat-icon mat-list-icon>credit_card</mat-icon>
        <span mat-line>Eliminar Observacion</span>
      </a>
      <a mat-list-item href="https://lookerstudio.google.com/reporting/c7160e39-1ad5-47e3-9a6d-57e3d84bee13/page/wm30B" target="_blank">
        <mat-icon mat-list-icon>web</mat-icon>
        <span mat-line>Informe</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <div>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Sistema escolar</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="openHelp()">
        <mat-icon>help</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <router-outlet></router-outlet>
</mat-sidenav-container>
