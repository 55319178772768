import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { defaultLanguage, languages } from '../shared/model/languages';
import { SpeechError } from '../shared/model/speech-error';
import { SpeechEvent } from '../shared/model/speech-event';
import { SpeechRecognizerService } from '../shared/services/web-apis/speech-recognizer.service';
import { ActionContext } from '../shared/services/actions/action-context';
import { SpeechNotification } from '../shared/model/speech-notification';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComentarioService } from 'src/app/comentario.service';
import { DatePipe } from '@angular/common';
import { EstudianteService } from '../estudiante.service'; 

@Component({
  selector: 'wsa-web-speech',
  templateUrl: './web-speech.component.html',
  styleUrls: ['./web-speech.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebSpeechComponent implements OnInit {
  languages: string[] = languages;
  currentLanguage: string = defaultLanguage;
  totalTranscript?: string;
  language?: string;
  fechaActual = new Date();
  selectedStudent!: string; // Variable para almacenar el estudiante seleccionado
  listTarjetas: any;
  comentario: any;
  //form: FormGroup;
  transcript$?: Observable<string>;
  listening$?: Observable<boolean>;
  errorMessage$?: Observable<string>;
  defaultError$ = new Subject<string | undefined>();

  constructor(
    private speechRecognizer: SpeechRecognizerService,
    private actionContext: ActionContext,
    private fb: FormBuilder, 
    //private toastr: ToastrService, 
    private _comentario: ComentarioService,
    private _estudiante: EstudianteService
  ) {}

  ngOnInit(): void {
    const webSpeechReady = this.speechRecognizer.initialize(this.currentLanguage);
    if (webSpeechReady) {
      this.initRecognition();
    }else {
      this.errorMessage$ = of('Your Browser is not supported. Please try Google Chrome.');
    }
    this.obtenerEstudiantes();
  }

    
  guardarPost(): void {
    // Suponiendo que necesitas algún dato específico para guardar el post,
    // puedes obtenerlo de this.totalTranscript u otro lugar según sea necesario.
    const postData = {
      // Supongamos que el mensaje es lo que quieres guardar como un post.
      
      //nombre: this.currentLanguage,
      //nombre:  this.fechaActual,
      nombre: this.selectedStudent,
      comentario: this.totalTranscript,
      fecha:  this.fechaActual,
    };

    // Llamar al método guardarPost() del servicio ComentarioService.
    console.log(postData);
    this._comentario.guardar(postData).subscribe(
      () => {
        console.log('Post guardado exitosamente');
        // Realizar cualquier lógica adicional después de guardar el post, si es necesario.
        this.totalTranscript = '';
      },
      (error) => {
        console.error('Error al guardar el post:', error);
        // Manejar errores si es necesario.
      }
      
    );
  }

  start(): void {
    if (this.speechRecognizer.isListening) {
      this.stop();
      return;
    }

    this.defaultError$.next(undefined);
    this.speechRecognizer.start();
  }

  stop(): void {
    this.speechRecognizer.stop();
  }

  selectLanguage(language: string): void {
    if (this.speechRecognizer.isListening) {
      this.stop();
    }
    this.currentLanguage = language;
    this.speechRecognizer.setLanguage(this.currentLanguage);
  }

  obtenerEstudiantes() {
    this._estudiante.obtener()
      .subscribe(data => {
        console.log(data);
        this.listTarjetas = data;
        console.log(this.comentario);
      })
  }

  private initRecognition(): void {
    this.transcript$ = this.speechRecognizer.onResult().pipe(
      tap((notification) => {
        this.processNotification(notification);
      }),
      map((notification) => notification.content || '')
    );

    this.listening$ = merge(
      this.speechRecognizer.onStart(),
      this.speechRecognizer.onEnd()
    ).pipe(map((notification) => notification.event === SpeechEvent.Start));

    this.errorMessage$ = merge(
      this.speechRecognizer.onError(),
      this.defaultError$
    ).pipe(
      map((data) => {
        if (data === undefined) {
          return '';
        }
        if (typeof data === 'string') {
          return data;
        }
        let message;
        switch (data.error) {
          case SpeechError.NotAllowed:
            message = `Cannot run the demo.
            Your browser is not authorized to access your microphone.
            Verify that your browser has access to your microphone and try again.`;
            break;
          case SpeechError.NoSpeech:
            message = `No speech has been detected. Please try again.`;
            break;
          case SpeechError.AudioCapture:
            message = `Microphone is not available. Plese verify the connection of your microphone and try again.`;
            break;
          default:
            message = '';
            break;
        }
        return message;
      })
    );
  }

  private processNotification(notification: SpeechNotification<string>): void {
    if (notification.event === SpeechEvent.FinalContent) {
      const message = notification.content?.trim() || '';
      this.actionContext.processMessage(message, this.currentLanguage);
      // this.actionContext.runAction(message, this.currentLanguage);
      this.totalTranscript = this.totalTranscript
        ? `${this.totalTranscript}\n${message}`
        : notification.content;
    }
  }
}
